import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import NextImage, { ImageProps as NextImageProps } from 'next/legacy/image'
import { forwardRef, useCallback, useRef, useState } from 'react'

import imageLoader from '~/components/Abstracts/Image/loader'
import Ratio from '~/components/Abstracts/Ratio'

import { Sizes, useSizesFromBreakpoints } from './maths'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ImageProps = Omit<NextImageProps, 'src' | 'width' | 'height'> & {
  sizesFromBreakpoints?: Sizes
  width?: number
  height?: number
  src: string
  resizeRect?: string
  ratio?: number
  asPlaceholder?: boolean
  placeholderClassName?: string
  loader?: (src, width, quality, rect) => string
}

function ImageForwarded(
  {
    sizes,
    layout,
    sizesFromBreakpoints,
    width,
    height,
    className,
    asPlaceholder = false,
    resizeRect,
    onLoadingComplete,
    ratio,
    onClick,
    placeholderClassName,
    loader,
    ...props
  }: ImageProps,
  ref,
) {
  const processedSizes = useSizesFromBreakpoints(
    sizesFromBreakpoints,
    props.src,
  )

  const [loaded, setLoaded] = useState(false)

  const placeholderRef = useRef(null)

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  }

  const onLoadingCompleteCallback = useCallback(
    (res) => {
      onLoadingComplete?.(res)
      asPlaceholder && setLoaded?.(true)
    },
    [onLoadingComplete, setLoaded, asPlaceholder],
  )

  const imageComponent = (
    <>
      <AnimatePresence>
        {asPlaceholder && (
          <m.span
            animate={loaded ? 'hide' : 'show'}
            variants={variants}
            ref={placeholderRef}
            className={cx(css.placeholder, placeholderClassName, {
              asPlaceholder,
            })}
          />
        )}
      </AnimatePresence>

      <NextImage
        sizes={processedSizes ? processedSizes : sizes}
        layout={layout}
        lazyBoundary="10px"
        {...(layout === 'fill' ? {} : { width, height })}
        loader={imageLoader}
        onLoadingComplete={onLoadingCompleteCallback}
        {...props}
      />
    </>
  )

  return props.src ? (
    <div ref={ref} className={cx(css.Image, className)} onClick={onClick}>
      {ratio ? (
        <Ratio ratio={ratio} styleContainer={{ position: 'relative' }}>
          {() => imageComponent}
        </Ratio>
      ) : (
        imageComponent
      )}
    </div>
  ) : null
}

const Image = forwardRef<HTMLDivElement, ImageProps>(ImageForwarded)

Image.defaultProps = {}

export type { Size, Sizes } from './maths'
export default Image
