import processEnvPublic from 'unlikely-env/public'
import { CartProduct, UnlikelyCartLine } from '~/@types/cart'
import linkResolver from '~/lib/link-resolver'

import { customAttributesAsObject } from '~/utils/custom-attributes'

import serializePrice from '~/data/serialize-price'

function getBundleItems(
  lineItems: UnlikelyCartLine[],
  mainLineItem: UnlikelyCartLine,
  locale,
): CartProduct {
  const attributes = customAttributesAsObject(mainLineItem?.attributes)
  const bundleId = attributes?._rootBundleId ?? null
  const customizations = mainLineItem.attributes
    .map((attr) => {
      if (!attr?.key.startsWith('_')) return { ...attr }
    })
    .filter(Boolean)
  const bundleLineItems = lineItems.filter((lineItem) => {
    const attributes = customAttributesAsObject(lineItem?.attributes)

    return attributes._bundleId === bundleId
  })

  const items = [
    mainLineItem,
    ...bundleLineItems.filter((item) => item?.id !== mainLineItem?.id),
  ]

  return {
    id: mainLineItem?.id,
    merchandiseId: mainLineItem?.merchandise?.id,
    price: serializePrice(
      locale,
      mainLineItem?.merchandise?.price?.currencyCode,
      mainLineItem?.merchandise?.price?.amount,
    ),
    title: mainLineItem?.merchandise?.product?.title,
    size: mainLineItem?.merchandise?.title,
    quantity: mainLineItem?.quantity,
    isGiftCard: attributes?._gift_card === 'true' || false,
    imageSrc:
      attributes._currentImgVisual ?? mainLineItem?.merchandise?.image?.url,
    line: mainLineItem,
    customizations,
    href: linkResolver({
      full_slug: `${locale}/${processEnvPublic(
        'NEXT_PUBLIC_PROJECT_NAME',
      )}/products/${attributes._slug}`,
    }),
    items: items ?? null,
    attributes: mainLineItem?.attributes,
  }
}

export default function serializeCartProducts(
  lines: UnlikelyCartLine[],
  locale: string,
): CartProduct[] {
  const p = lines
    ?.map((line) => {
      const customAttributes = customAttributesAsObject(line?.attributes)
      const isCartNote = Boolean(customAttributes._customMessage)
      const isMultiId = Boolean(customAttributes?._rootMultiId)
      const isBundleProduct = Boolean(
        customAttributes?._rootBundleId && !customAttributes?._multiId,
      )

      // We need to hide the cart note if present
      if (isCartNote) return null

      const isSimpleProduct = Boolean(
        !customAttributes?._rootBundleId &&
          !customAttributes?._multiId &&
          !customAttributes?._bundleId &&
          !customAttributes?._rootMultiId,
      )

      if (!isBundleProduct && !isMultiId && !isSimpleProduct) return null

      let bundles

      if (isMultiId) {
        const multiId = customAttributes?._rootMultiId ?? null
        const bundleLineItems = lines.filter((lineItem) => {
          const customAttributes = customAttributesAsObject(
            lineItem?.attributes,
          )
          return (
            customAttributes?._multiId === multiId &&
            customAttributes?._rootBundleId
          )
        })

        bundles = bundleLineItems
          .map((mainLine) => getBundleItems(lines, mainLine ?? null, locale))
          .flat() as CartProduct[]
      }

      if (isBundleProduct) {
        bundles = getBundleItems(lines, line, locale)
        return { ...bundles }
      }

      // If we've arrived here, we are serializing ByCharlot multibundle
      return {
        id: line?.id,
        isMultiBundle: isMultiId,
        merchandiseId: line?.merchandise?.id,
        price: serializePrice(
          locale,
          line?.merchandise?.price?.currencyCode,
          line?.merchandise?.price?.amount,
        ),
        title: line?.merchandise?.product?.title,
        size: line?.merchandise?.title,
        quantity: line?.quantity,
        isGiftCard: customAttributes?._gift_card === 'true' || false,
        imageSrc:
          customAttributes._currentImgVisual ?? line?.merchandise?.image?.url,
        line,
        href: linkResolver({
          full_slug: `${locale}/${processEnvPublic(
            'NEXT_PUBLIC_PROJECT_NAME',
          )}/products/${customAttributes._slug}`,
        }),
        products: bundles?.map((bundle) => {
          const { items, ...rest } = bundle
          return { ...rest }
        }),
        items: bundles
          ?.map((bundle) => bundle?.items)
          .concat(line)
          ?.flat() ?? [line],
        customAttributes: line?.attributes,
      }
    })
    .filter(Boolean)

  return p
}
